const concreto = [
    {
      id: 1,
      name: "Catálogo 1",
      imgURL:
        "assets/img/cat1.jpg"
    },
    {
      id: 2,
      name: "Catálogo 2",
      imgURL:
        "assets/img/cat2.jpg"
    }
      ,
    {
      id: 3,
      name: "Catálogo 3",
      imgURL:
        "assets/img/cat3.jpg"
    },
    {
      id: 4,
      name: "Catálogo 4",
      imgURL:
        "assets/img/cat4.jpg"
    },
    {
      id: 5,
      name: "Catálogo 5",
      imgURL:
        "assets/img/cat5.jpg"
    },
    {
      id: 6,
      name: "Catálogo 6",
      imgURL:
        "assets/img/cat6.jpg"
    },
    {
      id: 7,
      name: "Catálogo 7",
      imgURL:
        "assets/img/cat7.jpg"
    },
    {
      id: 8,
      name: "Catálogo 8",
      imgURL:
        "assets/img/cat8.jpg"
    },
    {
      id: 9,
      name: "Catálogo 9",
      imgURL:
        "assets/img/cat9.jpg"
    },
    {
      id: 10,
      name: "Catálogo 10",
      imgURL:
        "assets/img/cat10.jpg"
    },
    {
      id: 11,
      name: "Catálogo 11",
      imgURL:
        "assets/img/cat11.jpg"
    },
    {
      id: 12,
      name: "Catálogo 12",
      imgURL:
        "assets/img/cat12.jpg"
    }
  ];
  
  export default concreto;