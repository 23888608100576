import React from "react";
import Producto from "./Producto";
import servicios from "../servicios";

function createProducto(producto) {
    return (
      <Producto
        key={producto.id}
        name={producto.name}
        imgURL={producto.imgURL}
      />
    );
  }

function Servicios() {
    return (
        <div id="servicio">
            <div className="container-fluid p-0">
                <div className="row g-0">
                {servicios.map(createProducto)}
                </div>
            </div>
        </div>
    );
}

export default Servicios;