const fotos = [
    {id: 1,iURL: "assets/img/marketing/fotos/foto1.jpg"},
    {id: 2,iURL: "assets/img/marketing/fotos/foto2.jpg"},
    {id: 3,iURL: "assets/img/marketing/fotos/foto3.jpg"},
    {id: 4,iURL: "assets/img/marketing/fotos/foto4.jpg"},
    {id: 5,iURL: "assets/img/marketing/fotos/foto5.jpg"},
    {id: 6,iURL: "assets/img/marketing/fotos/foto6.jpg"},
    {id: 7,iURL: "assets/img/marketing/fotos/foto7.jpg"},
    {id: 8,iURL: "assets/img/marketing/fotos/foto8.jpg"},
    {id: 9,iURL: "assets/img/marketing/fotos/foto9.jpg"},
    {id: 10,iURL: "assets/img/marketing/fotos/foto10.jpg"},
    {id: 11,iURL: "assets/img/marketing/fotos/foto11.jpg"},
    {id: 12,iURL: "assets/img/marketing/fotos/foto12.jpg"},
    {id: 13,iURL: "assets/img/marketing/fotos/foto13.jpg"},
    {id: 14,iURL: "assets/img/marketing/fotos/foto14.jpg"},
    {id: 15,iURL: "assets/img/marketing/fotos/foto15.jpg"},
    {id: 16,iURL: "assets/img/marketing/fotos/foto16.jpg"},
    {id: 17,iURL: "assets/img/marketing/fotos/foto17.jpg"}
  ];
  
  export default fotos;